html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

#site-container,
#root {
  height: 100%;
  position: relative;
}

.cursor-dancer {
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 300ms;
}

.cursor-background-dancer {
  height: 2rem;
  width: 2rem;
  opacity: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-timing-function: cubic-bezier(0.25, 0.4, 0.55, 1.4);
  transition-duration: 500ms;
  animation-name: spinning;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.cursor-dancer img,
.cursor-background-dancer img {
  width: 100%;
}

@keyframes spinning {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.custom-no-scrollbar {
  scrollbar-width: none;
}